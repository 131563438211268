(function () {
    'use strict';

    angular.module('informaApp')
        .component('paginator', {
            templateUrl: 'components/paginator/template.html',
            controller: [PaginatorController],
            bindings: {
                total: '<',
                page: '<',
                itemsPerPage: '<',
                onPageChange: '<',
            }
        });

    function PaginatorController() {
        var vm = this;

        this.$onInit = function () {
            vm.isEnabled = isEnabled;
            vm.pagination = pagination;
            vm.changePage = changePage;
        };

        function changePage(page) {
            if (vm.isEnabled(page) && vm.page !== page) {
                vm.page = page;
                vm.onPageChange(vm.page);
            }
        }

        function isEnabled(page) {
            var lastPage = vm.total;
            return page <= lastPage && page >= 1;
        }

        function pagination() {
            var lastPage = vm.total;
            var delta = 2;
            var left = vm.page - delta;
            var right = vm.page + delta + 1;
            var range = [];
            var rangeWithDots = [];

            for (var i = 1; i <= lastPage; i++) {
                if (i === 1 || i === lastPage || i >= left && i < right) {
                    range.push(i);
                }
            }

            var prev;
            for (var i in range) {
                if (prev) {
                    if (range[i] - prev === delta) {
                        rangeWithDots.push(prev + 1);
                    } else if (range[i] - prev !== 1) {
                        rangeWithDots.push('...');
                    }
                }
                rangeWithDots.push(range[i]);
                prev = range[i];
            }

            return rangeWithDots;
        }
    }

})();